$prefix: dispatch_scan;

.#{$prefix} {
    &__header {
        background-color: black;
        width: 100%;
        height: 60px;
        font-family: Inter;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: normal;
        color: #ffffff;

        &_left {
            text-align: left;
        }

        &_center {
            text-align: center;
        }

        &_right {
            text-align: right;
        }
    }

    &__bag {
        background-color: #03c875;
        height: 700px;
    }

    &__warmer {
        background-color: $warmer-color;
        height: 700px;
    }

    &__idle {
        background-color: #e6e6e6;
        height: 700px;
    }

    &__dine-in {
        background-color: #4154ff;
        height: 700px;
    }

    &__takeaway {
        background-color: #fcab3f;
        height: 700px;
    }

    &__scanner_box_view {
        padding: 0px 50px;
    }

    &__scanner_box_header_row {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        width: 84%;
        margin: auto;
        position: relative;
        .icon_order_type {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50px;
            left: 0px;
        }
    }

    &_title {
        height: 174px;
        font-family: Inter;
        font-size: 144px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1a1a1a;

        &.text--white {
            color: $white;
        }
    }

    &__order_type_text {
        font-family: Inter;
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 44px;
        color: #131415;
        text-transform: uppercase;

        &.text--white {
            color: $white;
        }
    }

    &__bag_number {
        font-family: Inter;
        font-style: normal;
        font-weight: 900;
        font-size: 100px;
        line-height: 100px;
        text-align: center;
        color: #131415;
        &.text--white {
            color: $white;
        }
    }

    &__sku_text {
        font-family: Inter;
        font-style: normal;
        font-weight: 900;
        font-size: 50px;
        line-height: 61px;
        text-align: center;
        color: #131415;
        position: absolute;
        top: 40px;
        right: 0px;

        &.text--white {
            color: $white;
        }
    }

    &_subtitle {
        height: 44px;
        font-family: Inter;
        font-size: 36px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #131415;

        &.text--white {
            color: $white;
        }
    }

    &__scan_error {
        color: red;
        font-size: 50px;
    }

    &__icon {
        margin-right: 10px;
    }

    &_print {
        float: right;
        position: relative;
        top: 70px;
        width: 163px;
        height: 59px;
        &_icon {
            width: 29px;
            height: 15px;
        }

        &_off {
            width: 180px;
            height: 180px;
            margin-top: 114px;
        }
    }

    &__meal_images_section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 84%;
        margin: auto;
    }

    &__meal_img_title_container {
        margin: 24px 0px;
    }

    &__expected {
        margin-right: 24px;
    }

    &__capture_img_section {
        position: relative;
    }

    &__title_container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;

        .checkmark_svg {
            margin-right: 22px;
        }

        .title {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            color: #1a1a1a;
            text-transform: uppercase;
            padding: 10px 0px;
        }
    }

    &__meal_img {
        width: 536px;
        height: 430px;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__error_state {
        width: 536px;
        height: 430px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;

        .text {
            margin-top: 20px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            text-align: center;
            color: white;
        }
    }

    &__upload_picture_chip {
        position: absolute;
        bottom: 16px;
        right: 16px;
        background-color: #303234;
        color: white;
        border-radius: 102px;
        padding: 12px 16px;

        .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
        }
    }
}
