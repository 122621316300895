$prefix: cashier-pickup;

.#{$prefix} {
    &__dine-in {
        flex-wrap: wrap;
    }

    &__pickup-container {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}