.inventory-menu {
    margin-top: 20vh;
}

.sku-entry {
    &__progress {
        position: sticky;
        top: 46px;
        background: white;
        border-bottom: 2px solid $whitelabel-gray;
    }
}

.footer-bottom {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    border-top: 2px solid $whitelabel-gray;
    padding: 10px;
    width: 100%;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    align-items: center;

    &-1-1-1-1 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &-1-2 {
        grid-template-columns: 2fr 1fr;
    }

    &-gap-medium {
        grid-gap: 30px;
    }
}

.quantity-selector {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 16px 0;

    &-button {
        border: 0;
        background-color: transparent;
    }

    .icon {
        width: 24px;
        height: 24px;
    }
}

.history-table{
    width: 100%;

    th{
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: $whitelabel-green;
        color: white;
    }
    td{
        border: 1px solid #ddd;
        padding: 8px;
    }
    tr:nth-child(even){background-color: #f2f2f2;}
}

.history-modal{
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    span{
        font-size: 2.5rem;
        font-weight: bold;
    }
}

.sku-button-sm {
    width: 51px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    background-color: $whitelabel-green;
    border-color: $whitelabel-green;
    color: $white;
}

.sku-button-lg {
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    background-color: $whitelabel-green;
    border-color: $whitelabel-green;
    color: $white;
}

.button-red {
    background-color: red;
}

.button-orange {
    background-color: orange;
}
