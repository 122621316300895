$prefix: sku-item;
.#{$prefix} {
    &__item {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        font-family: $brand-bolder-font;
        font-size: 1.375rem;
        font-weight: bold;
        line-height: 1.375rem;
        height: 36px;
        border-bottom: 2px solid $whitelabel-lighter-black;
        padding: 5px;
        cursor: pointer;
        position: relative;
        &.global {
            background-color: #00bb78;
            color: $white;
        }
        &.in_warmer {
            background-color: $warmer-color;
            color: $white;
        }
        &.on_route {
            background-color: $route-color;
            // color: $white;
        }
        .remake_icon {
            position: absolute;
            right: 5px;
            margin-top: 3px;
        }
        .cycle-no {
            font-size: 0.75rem;
            margin-left: 0.375rem;
            position: relative;
            top: 2px;
        }
    }
    &__remake_modal {
        padding: 5.125rem 4.625rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__remake_modal_title {
        font-family: $brand-bolder-font;
        font-style: normal;
        font-weight: bold;
        font-size: 4.5rem;
        line-height: 1.375rem;
        color: black;
        &.remake-error {
            color: $whitelabel-red;
        }
    }
    &__remake_modal_desc {
        margin: 6.25rem 0px;
        font-family: $brand-bolder-font;
        font-style: normal;
        font-weight: normal;
        font-size: 2.25rem;
        line-height: 2.75rem;
        color: black;
        text-align: center;
        &.remake-error {
            font-weight: bold;
        }
    }
    &__submit_cancel {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__button {
        padding: 2rem;
        font-family: $brand-bolder-font;
        font-style: normal;
        font-weight: bold;
        font-size: 3rem;
        line-height: 1.375rem;
        text-align: center;
        color: white;
        border: none;
        border-radius: 5px;
        width: 18.75rem;
        &.cancel {
            background-color: $whitelabel-red;
        }
        &.confirm {
            background-color: $whitelabel-green;
            margin-left: 6.25rem;
        }
        &.dismiss {
            background-color: #131415;
            width: 43.375rem;
        }
    }
    &__customToast {
        display: flex;
        align-items: center;
        pointer-events: none;
        .message {
            margin-left: 0.75rem;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.75rem;
        }
    }
}
.Toastify__toast-container--top-right {
    top: 8rem !important;
    right: 1.5rem !important;
    @media (min-width: 1920px) {
        top: 5rem !important;
    }
}
.Toastify__toast-container {
    padding: 0px !important;
    width: 26.5rem !important;
    @media (min-width: 1920px) {
        width: 25.625rem !important;
    }
}
.Toastify__toast {
    background-color: #131415 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px !important;
    min-height: 0px !important;
    padding: 20px 12px 30px 20px !important;
    border-radius: 1rem !important;
    width: inherit !important;
    box-shadow: none !important;
}
.Toastify__toast-body {
    margin: 0px !important;
    padding: 0px !important;
    display: block !important;
    width: inherit !important;
    color: white !important;
}
.Toastify__close-button {
    align-self: center !important;
    color: white;
}
.Toastify__progress-bar--default {
    background: #03c875 !important;
    height: 12px !important;
}

.animate__animated.animate__slideInDown {
    --animate-duration: 300ms;
}
.animate__animated.animate__slideOutUp {
    --animate-duration: 300ms;
}
