.login {
    height: 100vh;
    background-color: $grey;
    .container {
        height: 100% !important;
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        padding: 0;
    }
    input {
        font-size: 18px;
        padding: 12px 20px;
        width: 100%;
    }
    button {
        font-size: 18px;
        padding: 12px 35px;
        background-color: $whitelabel-green;
        border: none;
    }
    .section-area {
        margin-bottom: 30px;
    }
    .dropdown {
        position: relative;
        display: inline-block;
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: white;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            right: 0;
            a {
                text-decoration: none;
                font-size: 16px;
                padding: 5px 10px !important;
                display: block;
            }
        }
        .dropdown-content a:hover {
            background-color: $whitelabel-gray;
        }
    }
    .dropdown:hover .dropdown-content {
        display: block;
    }
    &__hub-selector-container {
        margin-bottom: 50px;
    }
}
