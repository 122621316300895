* {
    box-sizing: border-box;
}

a {
    color: $black;
}

html,
body,
.App {
    height: 100vh !important;
    background-color: #f4f4f4 !important;
    text-align: center;
}

html {
    font-size: 10px;

    @media (min-width: 1280px) {
        font-size: 10px;
    }

    @media (min-width: 1920px) {
        font-size: 16px;
    }
}

.App {
    display: flex;
    flex-flow: column nowrap;
}

body {
    // overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
p,
input {
    font-family: $brand-primary-font;
}

button {
    outline: none;
    cursor: pointer;
    font-family: $brand-bolder-font;
    border-radius: 0;
}

button:disabled {
    opacity: 0.6;
    cursor: default;
}

.btn {
    padding: 12px;
    font-size: 14px;
    border: solid 1px #d1d1d1;
    background-color: #f9f9f9;
    border-radius: 5px;

    &.btn-small {
        padding: 6px 10px;
        font-size: 18px;
        width: 205px;
        text-align: left;

        .iconmoon-icon {
            float: right;
        }
    }

    &.btn-selected,
    &.btn-primary {
        color: $white;
        background-color: $whitelabel-green;
        border-color: $whitelabel-green;
    }

    &.btn-secondary {
        color: $white;
        background-color: $whitelabel-red;
        border-color: $whitelabel-red;
    }

    &.btn-black {
        background-color: #1a1a1a;
        color: $white;
    }

    &.btn-borderless {
        background-color: transparent;
        border: none;
        color: inherit;

        &:hover {
            background-color: transparent;
        }
    }

    &.btn-icon {
        display: flex;
        justify-content: space-around;
    }

    @include breakpoint($mobile) {
        font-size: 20px;
        padding: 12px 20px;
    }
}

.btn-group {
    display: flex;
    .btn {
        margin: 0;
        border-radius: 0;
        flex: 1 1 auto;
    }

    .btn:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .btn:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

h1 {
    font-size: $large;
}

h2 {
    font-size: $medium;
}

.IIV::-webkit-media-controls-play-button,
video::-webkit-media-controls-start-playback-button {
    opacity: 0;
    pointer-events: none;
    width: 5px;
}

label {
    line-height: 150%;
    font-size: 14px;
}

strong {
    font-family: $brand-boldest-font;
}

hr {
    border: none;
    border-bottom: 1px solid $whitelabel-line-separator;
}

input[type='number']:not(.input--show-spinner) {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

input:disabled {
    background: #cdcdcd;
    border: 1px solid black;
}

.fullscreen-enabled {
    background-color: #f4f4f4;
}
