body {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter-medium.eot') format('eot'),
        url('../fonts/inter-medium.woff') format('woff'),
        url('../fonts/inter-medium.ttf') format('truetype');
}

@font-face {
    font-family: 'InterBold';
    src: url('../fonts/inter-bold.eot') format('eot'),
        url('../fonts/inter-bold.woff') format('woff'),
        url('../fonts/inter-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'InterSemiBold';
    src: url('../fonts/inter-semibold.eot') format('eot'),
        url('../fonts/inter-semibold.woff') format('woff'),
        url('../fonts/inter-semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,100&display=swap');
}

@font-face {
    font-family: 'Barlow Semi Condensed', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;200;300;400;500;600;700;800&display=swap');
}
// Bootstrap
@import "bootstrap_custom";

// Blueprint.js
@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

// General components
@import 'variables';
@import 'helpers';
@import 'grid';
@import 'base';
@import 'login';
@import 'logo';
@import 'header';
@import 'loading';
@import 'modal';
@import 'badge';
@import 'icons';
@import 'switch-button';
@import '~react-toggle-switch/dist/css/switch.min.css';

// Page components
@import 'overview/imports';
@import 'checkIn/imports';
@import 'production/imports';
@import 'dispatch/dispatch';
@import 'dispatch-scan/dispatch-scan';
@import 'inventory/imports';
@import 'performance/performance';
@import 'live-dispatch/live-dispatch';
@import 'queue-screen/queue-screen';
@import 'pick-mode/pick-mode';
@import 'cashier-pickup/cashier-pickup';
@import 'cashier-pickup/cashier-pickup-order-card';
@import 'orders-kitchen/orders-kitchen';
@import 'warmer/warmer';
@import 'common/imports';

@import 'spacings';
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,100&display=swap');
