$largest: 64px;
$larger: 48px;
$large: 32px;
$medium: 16px;
$small: 8px;
$smallest: 4px;

$whitelabel-blue: #00acc6;
$whitelabel-red: #fb275f;
$whitelabel-green: #18d665;
$whitelabel-orange: #ffa400;
$whitelabel-purple: #8b199b;
$whitelabel-lightest: #efefef;
$whitelabel-lighter: #cdcdcd;
$whitelabel-light: #8b898d;
$whitelabel-dark: #535353;
$whitelabel-line-separator: #e4e4e4;
$whitelabel-black: #131415;
$whitelabel-gray: #e5e5e5;
$whitelabel-lighter-black: #303234;

$delay-red: #f4594f;

$white: white;
$black: black;
$grey: #252525;
$primary-yellow: #fec700;
$primary-purple: #7056b5;

$whitelabel-gray-bg: #fafafa;

$warmer-color: #ff642e;
$route-color: #80FF00;

$brand-primary-font: InterBold;
$brand-bolder-font: Inter;
$brand-boldest-font: InterSemiBold;

/* Device config breakpoints */
$desktop: 992px;
$tablet: 768px;
$mobile: 576px;
