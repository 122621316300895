$prefix: live-dispatch;
$breakpoint-tablet: 768px;
$breakpoint-ipad: 1280px;

.#{$prefix}__page {
    .fullscreen {
        flex: auto;
        display: flex;
        flex-flow: column nowrap;
        overflow-y: auto;
    }
}

.#{$prefix} {
    text-align: left;
    flex: 1 1 auto;
    font-family: $brand-bolder-font;

    &__webcam-area {
        background-color: #e6e6e6;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .webcam {
            height: 2px;
            width: 2px;
        }
        .img-to-show {
            height: 12.5rem;
            width: 12.5rem;
            object-fit: fill;
        }
    }

    &.delay {
        border-color: $delay-red;
    }
    &__dine-in-orders-container {
        // height: 90vh;
        // overflow-y: auto;
        background-color: $white;
        @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-ipad) {
            // width: 35%;
        }
    }

    &__heading {
        font-family: $brand-bolder-font;
        color: #1a1a1a;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: bold;
        line-height: 2.25rem;
        letter-spacing: 0px;
        padding: 16px 25px;
    }

    &__dine-in-container {
        // overflow-x: hidden;

        .items-length {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 1.125rem;
            line-height: 1.125rem;
        }
    }

    &__dine-in-table {
        border-collapse: collapse;
        color: #1a1a1a;
        padding-left: 25px;
        gap: 16px;
        flex-direction: column;
        max-height: 60vh;

        .order-th {
            padding-left: 1.5rem;
        }

        .items-th {
            padding-left: 1.25rem;
        }
    }

    &__dine-in-orders {
        padding: 0px;
    }

    .dine_in_icon {
        width: 44px;
    }

    .take_away_icon {
        width: 44px;
        height: 33px;
    }

    &__dine-in-order {
        border: 3px solid $whitelabel-lighter-black;
        width: 300px;

        &.delay {
            border: 3px solid $delay-red;
        }

        td {
            vertical-align: top;
            padding: 1.5rem 0px;
        }

        .order-info-container {            

            // .take_away_icon {
            //     width: 2.75rem;
            //     height: 2.5rem;
            //     filter: invert(1);
            // }

            // .dine_in_icon {
            //     width: 2.75rem;
            //     height: 1.875rem;
            //     filter: invert(1);
            // }

            .icon-number-name {
                display: flex;

                .number-name {

                    .pick_up_number {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.25rem;
                        letter-spacing: -0.5px;
                        font-family: $brand-bolder-font;
                    }

                    .customer_name {
                        margin-top: 0.25rem;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 0.875rem;
                        line-height: 1rem;
                        text-transform: uppercase;
                        font-family: $brand-bolder-font;
                    }

                    .aggregator_short_order_id {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.25rem;
                        letter-spacing: -0.5px;
                        font-family: $brand-bolder-font;
                        // font-style: normal;
                        // font-weight: bold;
                        // font-size: 1.5rem;
                        // line-height: 1.125rem;
                        // letter-spacing: -0.5px;
                        // font-family: $brand-bolder-font;
                        // padding-top: 12.5%;
                    }
                }
            }

            .order-amount-due {
                color: black;
                border-radius: 16px;
                font-family: $brand-bolder-font;
                font-style: normal;
                font-weight: bold;
                font-size: 0.68rem;
                background-color: $primary-yellow;
                text-align: center;
            }
        }

        .order-items-skus {
            display: flex;
            flex-wrap: wrap;

            div:last-child {
                border-bottom: none;        
            }
        }

        .order-time-box {
            display: flex;
            flex-direction: row;
            border-radius: 0.31rem;
            justify-content: center;
            align-items: flex-start;

            .print_icon {
                margin-left: 0.62rem;
                height: 1.18rem;
                width: 1.375rem;
                cursor: pointer;
            }

            .order-time {
                margin-left: 0.375rem;
                margin-right: 0.62rem;
                font-size: 1.25rem;
                font-style: normal;
                letter-spacing: 0px;
                text-align: center;
                width: 60px;
            }
        }
    }

    &__print_icon {
        height: 1.38rem;
        width: 1.375rem;
    }

    &__routes {
        background-color: $white;
        overflow: auto;
        // height: 20vh;
        min-width: 100vw;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    &__routes-container {
        padding: 0px 25px 16px;
        flex-direction: row;
        border-bottom: 3px solid $whitelabel-gray;
        gap: 14px;
    }

    &__route-left-content {
        flex-direction: column;        
        flex-grow: 4;        

        > div {
            // flex-grow: 1;
            padding: 12px 16px;
        }
    }

    &__route-number-and-dispatch {
        background-color: $whitelabel-black;
        color: $white;
    }

    &__route {
        display: flex;
        align-items: center;
        color: black;
        // width: 400px;
        max-width: 400px;
        border: 3px solid $whitelabel-black;

        .#{$prefix}__route-dispatch {
            height: 3.125rem;
            text-align: center;
        }

        .#{$prefix}__route-no {
            width: 2.81rem;
            height: 2.81rem;
            border-radius: 3.125rem;
            line-height: 2.25rem;
            border: 3px solid black;
            font-size: 1.25rem;
        }

        .#{$prefix}__dispatch-no {
            position: relative;
            bottom: 0.75rem;
            background-color: black;
            color: white;
            width: 2.81rem;
            height: 1rem;
            border-radius: 1.68rem;
            font-size: 0.75rem;
            line-height: 1.125rem;
        }

        .#{$prefix}__rider {
            font-size: 1.5rem;
            margin-left: 0.5rem;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 180px;
        }

        &.on_route {
            .#{$prefix}__route-no {
                border-color: $white;
                color: $white;
            }

            .#{$prefix}__dispatch-no {
                background-color: $white;
                color: $whitelabel-black;
            }

            .#{$prefix}__rider {
                color: $white;
            }
        }

        &.delay {
            color: $delay-red;

            .#{$prefix}__route-no {
                border-color: $white;
                color: $white;
            }

            .#{$prefix}__dispatch-no {
                background-color: $white;
                color: $whitelabel-black;
            }
        }
    }

    &__route-bags {        
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;

        .#{$prefix}__bag-info {
            margin-right: initial;
        }
    }

    &__print {
        padding: 0.375rem;

        .icon-print-medium {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    &__bags-container {
        // max-height: 90vh;
        // overflow: auto;
        // .bag-container-dine-enabled {
        //     width: 50%;
        //     @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-ipad) {
        //         width: 35%;
        //     }
        // }
    }
    .bag-container-dine-not-enabled {
        width: 75%;
    }

    &__bags {
        flex-direction: row;
        flex-wrap: wrap;
        writing-mode: vertical-lr;
        text-orientation: upright;
        height: 60%;
        padding-left: 25px;
    }

    &__bag {
        writing-mode: horizontal-tb;

        border: 3px solid $whitelabel-lighter-black;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
        // padding: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0.25rem;
        position: relative;
        width: 260px;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }

    &__bag-info {
        position: relative;
        display: flex;
        align-items: center;
        // margin-right: auto;
        // margin-bottom: 0.5rem;
        height: 40px;
    }

    &__bag-header {
        width: 100%;
        background-color: $whitelabel-lighter-black;
        padding: 10px;
        color: $white;
        justify-content: space-between;

        &.delay {
            background-color: $delay-red;
        }
    }

    &__bag-no {
        position: absolute;
        top: 10px;
        color: black;
        font-size: 20px;
        width: 45px;
        height: 1.125rem;
        text-align: center;
    }

    &__skus_container {
        width: 100%;
    }

    &__skus_container div:last-child {
        border-bottom: none;        
    }


    &__cutlery {
        font-size: 1.5rem;
        margin-top: 0.25rem;
    }

    &__countdown {
        font-size: 1.375rem;

        &.pickup {
            font-size: 0.875rem;
            font-family: $brand-bolder-font;
        }

        &-timer {
            display: inline-block;
            width: 4.18rem;

            &.delay {
                color: $delay-red;
            }
        }
    }

    &__print {
        border: 0;
        width: 40px;
        height: 40px;
    }

    //modal related classes:
    &__modal {
        writing-mode: horizontal-tb;
        color: $whitelabel-black;
        font-style: normal;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5.5rem 0px;

        .modal-amount-paid {
            color: $whitelabel-red;
        }
    }

    &__print_text {
        font-family: $brand-bolder-font;
        font-size: 4.5rem;
        line-height: 1.37rem;
        text-align: center;
        color: #000000;
        font-weight: bold;
    }

    &__modal_order {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .order_type_text {
            font-family: $brand-bolder-font;
            font-size: 2.25rem;
            line-height: 1.375rem;
            color: black;
            margin-bottom: 3rem;
            margin-top: 2rem;
            font-weight: bold;
        }
    }

    &__print_bag_qr_btn {
        background: $whitelabel-black;
        border-radius: 5px;
        padding: 2rem;
        font-family: $brand-bolder-font;
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.375rem;
        color: #ffffff;
        width: 18.75rem;
        margin: 0.625rem 0px;
        border: none;
    }

    &__cancel_button {
        margin-top: 2.5rem;
        width: 18.75rem;
        padding: 2rem;
        background: #fb275f;
        font-family: $brand-bolder-font;
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.375rem;
        color: #ffffff;
        border: none;
        border-radius: 5px;
    }

    &__printer_fail {
        .print_text {
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 4.5rem;
            line-height: 1.375rem;
            text-align: center;
            color: #fb275f;
        }
        .no_printer {
            margin-top: 100px;
            font-family: $brand-bolder-font;
            font-style: normal;
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 2.75rem;
            text-align: center;
            color: black;
        }
    }

    &__dismiss_button {
        margin-top: 8.25rem;
        padding: 2rem 15.625rem;
        background: $whitelabel-black;
        font-family: $brand-bolder-font;
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.375rem;
        color: #ffffff;
        border: none;
        border-radius: 5px;
    }

    &__customToast {
        display: flex;
        align-items: center;
        .check_svg {
            width: 1.875rem;
            height: 1.3125rem;
        }
        .message {
            margin-left: 0.75rem;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.75rem;
        }
    }

    .icon-bag {
        height: 35px;
        margin-right: 0.5rem;
        width: 45px;
    }

    .icon-cancel {
        width: 2.93rem;
        height: 2.93rem;
        position: absolute;
        top: -3px;
    }

    .icon-cutlery {
        height: 30px;
        width: 30px;
        transform: scale(1.45) translateY(10px);
        filter: invert(1);
    }
}

.Toastify__toast-container--top-right {
    top: 6.8rem !important;
    right: 1.5rem !important;
    @media (min-width: 1920px) {
        top: 5rem !important;
    }
}

.Toastify__toast-container {
    padding: 0px !important;
    width: 27rem !important;
    @media (min-width: 1920px) {
        width: 25.625rem !important;
    }
}

.Toastify__toast {
    background-color: #131415 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px !important;
    min-height: 0px !important;
    padding: 20px 12px 30px 20px !important;
    border-radius: 1rem !important;
    width: inherit !important;
    box-shadow: none !important;
}

.Toastify__toast-body {
    margin: 0px !important;
    padding: 0px !important;
    display: block !important;
    width: inherit !important;
    color: white !important;
}

.Toastify__close-button {
    align-self: center !important;
    color: white;
}

.Toastify__progress-bar--default {
    background: #03c875 !important;
    height: 12px !important;
}
.animate__animated.animate__slideInDown {
    --animate-duration: 300ms;
}

.animate__animated.animate__slideOutUp {
    --animate-duration: 300ms;
}
