.header {
    background-color: $grey;

    &__container {
        justify-content: space-between;
        padding: 20px 32px 16px;
    }
    &__hub-details {
        margin: auto;
        color: white;
    }

    &__title {
        font-family: $brand-bolder-font;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__fullscreen_btn {
        background-color: transparent;
        border: none;
        margin-right: 16px;
    }

    &__dropdown {
        cursor: pointer;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        text-transform: capitalize;
        padding: 10px 15px;
        border-radius: 8px;
        background-color: $white;
        border: none;

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: white;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            right: 0;
            a {
                font-family: $brand-bolder-font;
                text-decoration: none;
                font-size: 16px;
                padding: 20px !important;
                display: block;
                position: relative;
                z-index: 100;
            }
        }
        .dropdown-content:hover {
            background-color: $whitelabel-gray;
        }
    }

    .signout {
        margin-left: 18px;
        padding: 0px;
        background-color: $grey;
    }

    &__dropdown:hover .dropdown-content {
        display: block;
    }
    &__ellipsis {
        width: 22px;
        cursor: pointer;
    }
}
